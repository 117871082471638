<template>
        <v-row>
            <v-col>
                <v-row class="sub-title-img-pc hidden-sm-and-down" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                        </h1>
                    </v-col>
                </v-row>
                <v-row class="sub-title-img-mo hidden-md-and-up" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                        </h1>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-container>
                            <v-row class="px-3 my-8">
                                <v-col cols="12">
                                    <v-row>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <table class="readTable">
                                            <colgroup>
                                                <col style="width:20%"/>
                                                <col style="width:80%"/>
                                            </colgroup>
                                            <template v-if="isLogin">
                                                <tr>
                                                    <th class="th">작성자</th>
                                                    <td class="td">
                                                        <input id="lstModPrs" v-model="boardInfo.lstModPrs" placeholder="작성자" readonly>
                                                    </td>
                                                </tr>
                                            </template>
                                            <template v-else>
                                                <tr>
                                                    <th class="th">작성자</th>
                                                    <td class="td">
                                                        <input id="lstModPrs" v-model="boardInfo.lstModPrs" placeholder="작성자">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="th">비밀번호</th>
                                                    <td class="td">
                                                        <input type="password" id="boardPw" v-model="boardInfo.boardPw" placeholder="비밀번호">

                                                    </td>
                                                </tr>
                                            </template>

                                            <tr style="border-bottom:gray 2px solid;">
                                                <th class="th">제목</th>
                                                <td class="td">
                                                    <input id="boardTitle" v-model="boardInfo.boardTitle" placeholder="제목">

                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="td" colspan="2">
                                                    <div class="editor-container">
                                                        <div id="editor" style="height: 480px;">
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        <!--<v-col cols="12">
                                            <div class="editor-container">
                                                <div id="editor" style="height: 490px;">
                                                </div>
                                            </div>
                                        </v-col>-->

                                            <tr>
                                                <td class="td pa-3" colspan="2" style="text-align: center">
                                                    <v-btn
                                                            color="primary"
                                                            class="mx-4 white--text center-block"
                                                            @click="btnClick(false)"
                                                    >
                                                        취소
                                                    </v-btn>
                                                    <v-btn
                                                            color="primary"
                                                            class="mx-4 white--text center-block"
                                                            @click="btnClick(true)"
                                                    >
                                                        저장
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </table>
                                    </v-row>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>
</template>

<script>

    export default {
        name: 'Write',

        components: {
            //editor: Editor
        },
        data: () => ({
            boardInfo: {
                boardGubun: "",
                boardTitle:"",
                boardPw: "",
                boardContent:"",
                regDate:"",
                lstModPrs:"",
                lstModDate:"",
                boardThumb: "",
            },
            title:"",
            editorText: "",
            isThumbnail: false,
            quill: null,
            boardId: "",
            boardGubun: "",
            isLogin: false,

        }),
        created: function(){
            window.scrollTo(0,0);
            // 1: 공지사항 , 2: 포토 , 3: 후기, 4: 고객센터
            this.boardId = this.$getParameterByName('boardId');
            this.boardGubun = this.$getParameterByName('boardGubun');

            if (this.boardGubun === "") {
                alert("게시판 정보가 없습니다. 다시 시도해주세요.");
                window.history.back();
            }

            if (this.$getCookie("AUTH") === 'true') {
                this.isLogin = true;
                this.boardInfo.boardPw = this.$store.state.admin.loginInfo.usrPw;
                //this.boardInfo.lstModPrs = this.$store.state.admin.loginInfo.usrName;
                this.boardInfo.lstModPrs = this.$store.state.admin.loginInfo.homeNm;
            } else if(this.$store.state.admin.loginInfo.homeNm !== undefined){
                this.isLogin = true;
                this.boardInfo.boardPw = this.$store.state.admin.loginInfo.usrPw;
                this.boardInfo.lstModPrs = this.$store.state.admin.loginInfo.homeNm;
            } else {

                this.isLogin = false;
            }

            switch (this.boardGubun) {
                case '2' :
                case '1' : {
                    if(this.$store.state.admin.loginInfo.usrLvl > '8'){
                        this.boardInfo.boardPw = this.$store.state.admin.loginInfo.usrPw;
                        //this.boardInfo.lstModPrs = this.$store.state.admin.loginInfo.usrName;
                        this.boardInfo.lstModPrs = this.$store.state.admin.loginInfo.homeNm;
                    }else{
                        alert("작성 권한이 없습니다.");
                        window.history.back();
                    }
                    break;
                }
            }

            if (this.boardId != undefined && this.boardId != "") {
                this.getData(this.boardId);
            }

        },
        mounted() {
            this.$nextTick(() => {

                let _this = this;

                var options = {
                    modules: {
                        toolbar: {
                            container: [
                                ['bold', 'italic', 'underline', 'strike'],
                                [{'align': []}, {'size': ['small', false, 'large', 'huge']}],
                                [{'color': []}, {'background': []}],
                                ['image'],
                            ],
                        },
                    },
                    placeholder: '내용을 입력해 주세요.',
                    theme: 'snow',

                };

                this.quill = new Quill('#editor', options);
                this.quill.getModule('toolbar').addHandler('image', function() {
                    _this.changeImage();
                });

            })

        },
        methods: {
            getData(val){
                return this.$store.dispatch("board/getBoardInfo", {boardId: val})
                    .then((resp) => {
                        setTimeout(() => {

                            this.boardInfo = resp.message;
                            this.quill.root.innerHTML = this.boardInfo.boardContent;
                        }, 300)
                    })
                    .catch((err) => {
                    })
            },

            changeImage: function (e) {
                let _this = this;

                const input = document.createElement('input');
                input.setAttribute('type','file');
                input.setAttribute('accept', 'image/*');
                input.click();

                input.onchange = function() {

                    const fd = new FormData();
                    const file = $(this)[0].files[0];

                    fd.append('file',file);

                    let url = '/file/upload.do';

                    let header = {
                        headers: {'Content-Type': 'multipart/form-data'}
                    };

                    return axios.post(url, fd, header).then(resp => {
                        if (resp.data) {

                            const range = _this.quill.getSelection();
                            //_this.quill.insertEmbed(range.index, 'image', 'http://localhost:8082/file/'+resp.data);
                            _this.quill.insertEmbed(range.index, 'image', resp.data);
                            return resp.data
                        }
                        throw new Error('Server or network error');
                    });
                }
            },
            btnClick(val){
                if(val === true){

                    var content = this.quill.root.innerHTML;

                    //var rex = /<img[^>]+src="?([^"\s]+)"?\s*\/>/g;
                    var rex1 = /<img[^>]+src="([^">]+)"/g;
                    var results = rex1.exec(content);

                    let formdata = {
                        boardGubun: this.boardGubun

                    };

                    if (this.boardId !== "") {
                        formdata['boardId'] = this.boardId;
                    }

                    if(results !== null){
                        if(results[1] !== null){
                            //this.boardInfo.boardThumb = results[1];
                            formdata['boardThumb'] = results[1];
                        }else{
                            //this.boardInfo.boardThumb = "";
                            formdata['boardThumb'] = "";
                        }
                    }else {
                        formdata['boardThumb'] = "";
                    }


                    //this.boardInfo.boardContent = content;
                    formdata['boardContent'] = content;


                    if(this.boardInfo.lstModPrs.length === 0){
                        alert("작성자를 입력해 주세요.");
                        return;
                    }else{
                        formdata['lstModPrs'] = this.boardInfo.lstModPrs;
                    }

                    if(this.boardInfo.boardPw.length === 0){
                        alert("비밀번호를 입력해 주세요.")
                        return;
                    }else{
                        formdata['boardPw'] = this.boardInfo.boardPw;
                    }

                    if(this.boardInfo.boardTitle.length === 0){
                        alert("제목을 입력해 주세요.")
                        return;
                    }else{
                        formdata['boardTitle'] = this.boardInfo.boardTitle;
                    }

                    return this.$store.dispatch("board/updateBoard", formdata)
                        .then((resp) => {
                            setTimeout(() => {

                                location.href = "/community/read" + '?boardId=' + resp.message.boardId+'&boardGubun='+resp.message.boardGubun;

                                //this.$router.push({path: "/community/read"});

                            }, 300)
                        })
                        .catch((err) => {
                        })
                }else{
                    window.history.back();
                }

            },

        }

    }
</script>

<style scoped>

</style>

